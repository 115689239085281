import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-stage-contact',
  templateUrl: './stage-contact.component.html',
  styleUrls: ['./stage-contact.component.scss'],
})
export class StageContactComponent {
  contactForm: FormGroup;
  @Input() component;

  modalOpen = false;
  hasInput = false;

  constructor(private formBuilder: FormBuilder) {}

  toggleModal() {
    this.modalOpen = !this.modalOpen;
  }

  submit(form) {
    this.toggleModal();
  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({});
    this.component.contact.forEach(function(entry) {
      this.contactForm.addControl(
        entry.id,
        new FormControl('', Validators.required)
      );
    }, this);
  }
}
