// Modules
import { Component, Input } from '@angular/core';

// Definitions
export interface ILink {
  label: string;
  url: string;
  type: 'internalLink' | 'externalLink';
  newTab?: boolean;
}

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input() link: ILink;
  @Input() linkType: 'link' | 'button';
  @Input() icon?: string;
}
