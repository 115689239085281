// Modules
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { INavigationLink } from '../header-navigation-link/header-navigation-link.component';
import { IHeader } from '../../reducers/header';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  navigation$: Observable<INavigationLink[]>;
  subNavigationsItems: INavigationLink[];

  constructor(private store: Store<IHeader>) {
    this.navigation$ = this.store.pipe(select('header', 'navigation'));
  }

  hideSubNav = () => (this.subNavigationsItems = []);

  handleSubNav(navitem: INavigationLink) {
    this.subNavigationsItems = navitem.children || [];

    // When there is a submenu the first item is always "Overview"
    if (this.subNavigationsItems.length) {
      const overviewMenuitem = Object.assign({}, navitem, {
        children: [],
        label: 'Overview',
      });

      this.subNavigationsItems = [
        overviewMenuitem,
        ...this.subNavigationsItems,
      ];
    }
  }
}
