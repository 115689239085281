// Modules
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Definitions
import { INavigationLink } from '../header-navigation-link/header-navigation-link.component';

@Component({
  selector: 'app-header-subnavigation',
  templateUrl: './header-subnavigation.component.html',
  styleUrls: ['./header-subnavigation.component.scss'],
})
export class HeaderSubnavigationComponent {
  @Input() navigationsItems: INavigationLink[];
  @Output() hideSubNav = new EventEmitter<any>();

  onLeave() {
    this.hideSubNav.emit();
  }
}
