// Modules
import { Component, Input } from '@angular/core';

// Definitions
import { ILink } from '../link/link.component';

interface IBenefit {
  title: string;
  icon?: string;
  button?: ILink;
}

interface IBenefitsTile {
  type: 'benefitsTile';
  title: string;
  preTitle?: string;
  description?: string;
  benefits: IBenefit[];
}

// Component
@Component({
  selector: 'app-benefits-tile',
  templateUrl: './benefits-tile.component.html',
  styleUrls: ['./benefits-tile.component.scss'],
})
export class BenefitsTileComponent {
  @Input() component: IBenefitsTile;
}
