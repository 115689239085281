// Modules
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// Actions
import { initFooterAction } from '../../actions/footer';
import { initHeaderAction } from '../../actions/header';
import { initSettingsAction } from '../../actions/settings';

// Helper
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-layout-default',
  templateUrl: './layout-default.component.html',
  styleUrls: ['./layout-default.component.scss'],
})
export class LayoutDefaultComponent implements OnInit {
  constructor(private apiService: ApiService, private store: Store<{}>) {}

  async ngOnInit() {
    // Init the data on start
    const result = await this.apiService.getMeta();

    // Dispatch to store
    this.store.dispatch(initFooterAction(result));
    this.store.dispatch(initHeaderAction(result));
    this.store.dispatch(initSettingsAction(result));
  }
}
