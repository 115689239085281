// Modules
import { createReducer, on } from '@ngrx/store';

// Actions
import { initFooterAction } from '../actions/footer';

// Definitions
import { ISocialMediaItem } from '../components/footer-social-media/footer-social-media.component';
import { INavigationLink } from '../components/header-navigation-link/header-navigation-link.component';
import { IFooterSection } from '../components/footer-pre-navigation/footer-pre-navigation.component';
import { IImage } from '../components/image/image.component';

export interface IFooter {
  slogan: string;
  socialMedia: ISocialMediaItem[];
  mainNavigation: INavigationLink[];
  pre: {
    sections: IFooterSection[];
  };
}

export interface IFooterAPI {
  footer_content: {
    slogan: string;
    illustration: IImage;
  };
  footer_main: INavigationLink[];
  social_media: ISocialMediaItem[];
  footer_pre: IFooterSection[];
}

export interface IFooterAction extends IFooterAPI {
  type: string;
}

// Defaults
export const initialState = {
  slogan: '',
};

// Reducer
const initFooter = (state: IFooter, settings: IFooterAPI) => {
  // Get only neccessary keys
  const {
    footer_content: { slogan, illustration },
    social_media,
    footer_main,
    footer_pre,
  } = settings;

  return {
    ...state,
    socialMedia: social_media,
    mainNavigation: footer_main,
    pre: {
      sections: footer_pre,
      illustration,
    },
    slogan,
  };
};

export function footerReducer(state: IFooter, action: IFooterAction) {
  return createReducer(initialState, on(initFooterAction, initFooter))(
    state,
    action
  );
}
