// Modules
import { Component, OnInit, Input } from '@angular/core';

// Definitions
import { INavigationLink } from '../header-navigation-link/header-navigation-link.component';
import { ISocialMedia } from '../footer-social-media/footer-social-media.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() socialMedia: ISocialMedia[];
  @Input() main: INavigationLink[];

  copyLabel = 'TODO';
  image = 'TODO';

  constructor() {}

  ngOnInit() {}
}
