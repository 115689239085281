import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  styles: ['.left { background-color: {{ component.components.backgroundColor }};'],
})

export class InfoBoxComponent {
  @Input() component;
}
