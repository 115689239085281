// Modules
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { ILink } from '../link/link.component';
import { IFooter } from '../../reducers/footer';
export interface ISocialMediaItem extends ILink {
  icon: string;
}

export interface ISocialMedia {
  social: ISocialMediaItem[];
}
@Component({
  selector: 'app-footer-social-media',
  templateUrl: './footer-social-media.component.html',
  styleUrls: ['./footer-social-media.component.scss'],
})
export class FooterSocialMediaComponent {
  socialMedia$: Observable<ISocialMedia>;

  constructor(private store: Store<IFooter>) {
    this.socialMedia$ = this.store.pipe(select('footer', 'socialMedia'));
  }
}
