// Modules
import { Component, Input } from '@angular/core';

// Definitions
import { IImage } from 'src/app/components/image/image.component';
import { ILink } from 'src/app/components/link/link.component';
export interface IContentColumn {
  image: IImage;
  links: ILink[];
  text: string;
  title: string;
  preTitle?: string;
  subTitle?: string;
  teaser?: string;
  type: 'generic' | 'story';
  backgroundColor: string;
}
interface IContentTeaserHalf {
  type: 'contentTeaserHalf';
  left: IContentColumn;
  right: IContentColumn;
}

// Component
@Component({
  selector: 'app-content-teaser-half',
  templateUrl: './content-teaser-half.component.html',
  styleUrls: ['./content-teaser-half.component.scss'],
})
export class ContentTeaserHalfComponent {
  @Input() component: IContentTeaserHalf;
}
