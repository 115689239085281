// Modules
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// Definitions
import { INavigationLink } from '../header-navigation-link/header-navigation-link.component';

@Component({
  selector: 'app-header-navigation-mobile-item',
  templateUrl: './header-navigation-mobile-item.component.html',
  styleUrls: ['./header-navigation-mobile-item.component.scss'],
})
export class HeaderNavigationMobileItemComponent {
  @Input() item: INavigationLink;

  @Output() hideNav: EventEmitter<boolean> = new EventEmitter<boolean>();

  isActive: boolean;
  overviewItem: INavigationLink;

  constructor(private router: Router) {}

  handleNavigate() {
    // Always hide navigation on click
    this.hideNav.emit(true);
  }

  navigate() {
    if (this.item.children && this.item.children.length) {
      this.isActive = !this.isActive;

      this.overviewItem = Object.assign({}, this.item.children[0], {
        label: 'Overview',
      });
    } else {
      this.router.navigate([this.item.url]);
      this.handleNavigate();
    }
  }
}
