// Modules
import { Component, Input } from '@angular/core';

// Defijitions
import { ILink } from '../link/link.component';

interface IFeature {
  title: string;
  description?: string;
}
interface IGlobalNetworkTeaser {
  title: string;
  description?: string;
  features: IFeature[];
  button: ILink;
}

@Component({
  selector: 'app-global-network-teaser',
  templateUrl: './global-network-teaser.component.html',
  styleUrls: ['./global-network-teaser.component.scss'],
})
export class GlobalNetworkTeaserComponent {
  @Input() component: IGlobalNetworkTeaser;
}
