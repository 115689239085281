// Modules
import { Component, Input, OnInit } from '@angular/core';

// Helper
import { environment } from '../../../environments/environment';

// Definitions
import { IImage } from 'src/app/components/image/image.component';
import { ILink } from 'src/app/components/link/link.component';

interface IStage {
  button: ILink;
  description: string;
  image: IImage;
  leftBackgroundColor: string;
  links: ILink[];
  media: {
    type: string;
    url: string;
  };
  preTitle: string;
  rightBackgroundColor: string;
  title: string;
  type: 'stage';
}

// Component
@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss'],
  styles: [
    '.left { background-color: {{ component.components.backgroundColor }};',
  ],
})
export class StageComponent implements OnInit {
  @Input() component: IStage;

  modalOpen = false;
  youTubeVideoId: string;

  toggleModal() {
    this.modalOpen = !this.modalOpen;
  }

  getUrlParameter(url: string, parameter: string) {
    parameter = parameter.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + parameter + '=([^&#]*)');
    const results = regex.exec(url);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  ngOnInit() {
    const { media } = this.component;

    // If there is a video extract the youtube id
    if (media && media.type === 'youtube') {
      this.youTubeVideoId = this.getUrlParameter(media.url, 'v');
    }
  }
}
