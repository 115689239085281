import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  styles: ['.left { background-color: {{ component.components.backgroundColor }};'],
})

export class QuoteComponent {
  @Input() component;
}
