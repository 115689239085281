import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-download-box',
  templateUrl: './download-box.component.html',
  styleUrls: ['./download-box.component.scss'],
  styles: ['.left { background-color: {{ component.components.backgroundColor }};'],
})

export class DownloadBoxComponent {
  @Input() component;
}
