// Modules
import { createReducer, on } from '@ngrx/store';

// Actions
import { initHeaderAction } from '../actions/header';

// Definitions
import { INavigationLink } from '../components/header-navigation-link/header-navigation-link.component';
import { IHeaderContactUs } from '../components/header-contact-us/header-contact-us.component';

export interface IHeader {
  navigation: INavigationLink[];
  contact: IHeaderContactUs;
}

export interface IHeaderAPI {
  navigation: INavigationLink[];
  contact: IHeaderContactUs;
}

export interface IHeaderAction extends IHeaderAPI {
  type: string;
}

// Defaults
export const initialState = {
  navigation: [],
};

// Reducer
const initHeader = (state: IHeader, settings: IHeaderAPI) => {
  // Get only neccessary keys
  const { navigation, contact } = settings;

  return {
    ...state,
    navigation,
    contact,
  };
};

export function headerReducer(state: IHeader, action: IHeaderAction) {
  return createReducer(initialState, on(initHeaderAction, initHeader))(
    state,
    action
  );
}
