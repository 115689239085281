// Modules
import { Component, Input, OnInit } from '@angular/core';

// Helper
import { environment } from '../../../environments/environment';

// Definitions
export interface IImage {
  url: string;
  title: string;
}

// Component
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  @Input() image: IImage;

  urlPrefix = '';
  url: string;

  ngOnInit() {
    // Get the correct link
    this.url = this.image.url;
  }
}
