// Modules
import { Component, Input } from '@angular/core';

// Component
@Component({
  selector: 'app-text-html',
  templateUrl: './text-html.component.html',
  styleUrls: ['./text-html.component.scss'],
})
export class TextHtmlComponent {
  @Input() text: string;
}
