// Modules
import { Component, Input } from '@angular/core';

// Definitions
import { ILink } from '../link/link.component';

interface ITeaserElements {
  title: string;
  icon: string;
}
interface IProductTeaserFull {
  backgroundColor: string;
  button: ILink;
  elements: ITeaserElements[];
  subTitle: string;
  title: string;
}
@Component({
  selector: 'app-product-teaser-full',
  templateUrl: './product-teaser-full.component.html',
  styleUrls: ['./product-teaser-full.component.scss'],
})
export class ProductTeaserFullComponent {
  @Input() component: IProductTeaserFull;
}
