// Modules
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { NxPopoverModule } from '@allianz/ngx-ndbx/popover';

// Components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BenefitsTileComponent } from './components/benefits-tile/benefits-tile.component';
import { ContentTeaserHalfComponent } from './components/content-teaser-half/content-teaser-half.component';
import { ContentTeaserHalfGenericComponent } from './components/content-teaser-half-generic/content-teaser-half-generic.component';
import { ContentTeaserHalfStoryComponent } from './components/content-teaser-half-story/content-teaser-half-story.component';
import { DownloadBoxComponent } from './components/download-box/download-box.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterMainNavigationComponent } from './components/footer-main-navigation/footer-main-navigation.component';
import { FooterPreNavigationComponent } from './components/footer-pre-navigation/footer-pre-navigation.component';
import { FooterSloganComponent } from './components/footer-slogan/footer-slogan.component';
import { FooterSocialMediaComponent } from './components/footer-social-media/footer-social-media.component';
import { GlobalNetworkLinklistComponent } from './components/global-network-linklist/global-network-linklist.component';
import { GlobalNetworkTeaserComponent } from './components/global-network-teaser/global-network-teaser.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderContactUsComponent } from './components/header-contact-us/header-contact-us.component';
import { HeaderNavigationComponent } from './components/header-navigation/header-navigation.component';
import { HeaderNavigationLinkComponent } from './components/header-navigation-link/header-navigation-link.component';
import { HeaderNavigationMobileComponent } from './components/header-navigation-mobile/header-navigation-mobile.component';
import { HeaderNavigationMobileItemComponent } from './components/header-navigation-mobile-item/header-navigation-mobile-item.component';
import { HeaderSubnavigationComponent } from './components/header-subnavigation/header-subnavigation.component';
import { HorizontalDividerComponent } from './components/horizontal-divider/horizontal-divider.component';
import { ImageComponent } from './components/image/image.component';
import { ImageFullSizeComponent } from './components/image-full-size/image-full-size.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { LayoutDefaultComponent } from './components/layout-default/layout-default.component';
import { LinkBackComponent } from './components/link-back/link-back.component';
import { LinkComponent } from './components/link/link.component';
import { MainContentElementComponent } from './components/main-content-element/main-content-element.component';
import { NotfoundRouteComponent } from './components/notfound-route/notfound-route.component';
import { NxButtonModule } from '@allianz/ngx-ndbx/button';
import { NxCopytextModule } from '@allianz/ngx-ndbx/copytext';
import { NxErrorModule } from '@allianz/ngx-ndbx/base';
import { NxFooterModule } from '@allianz/ngx-ndbx/footer';
import { NxGridModule } from '@allianz/ngx-ndbx/grid';
import { NxHeaderModule } from '@allianz/ngx-ndbx/header';
import { NxHeadlineModule } from '@allianz/ngx-ndbx/headline';
import { NxIconModule } from '@allianz/ngx-ndbx/icon';
import { NxInputModule } from '@allianz/ngx-ndbx/input';
import { NxLinkModule } from '@allianz/ngx-ndbx/link';
import { NxModalModule } from '@allianz/ngx-ndbx/modal';
import { NxPaginationModule } from '@allianz/ngx-ndbx/pagination';
import { NxSpinnerModule } from '@allianz/ngx-ndbx/spinner';
import { NxTabsModule } from '@allianz/ngx-ndbx/tabs';
import { NxVideoModule } from '@allianz/ngx-ndbx/video';
import { ProductTeaserFullComponent } from './components/product-teaser-full/product-teaser-full.component';
import { QuoteComponent } from './components/quote/quote.component';
import { RouteGlobalComponent } from './components/route-global/route-global.component';
import { RouteNotfoundComponent } from './components/route-notfound/route-notfound.component';
import { SolutionTeaserBoxesComponent } from './components/solution-teaser-boxes/solution-teaser-boxes.component';
import { SpacerComponent } from './components/spacer/spacer.component';
import { StageComponent } from './components/stage/stage.component';
import { StageContactComponent } from './components/stage-contact/stage-contact.component';
import { TeaserBoxComponent } from './components/teaser-box/teaser-box.component';
import { TextComponent } from './components/text/text.component';
import { TextFullSizeComponent } from './components/text-full-size/text-full-size.component';
import { TextHeaderImageLinkComponent } from './components/text-header-image-link/text-header-image-link.component';
import { TextHtmlComponent } from './components/text-html/text-html.component';

// Reducer
import { settingsReducer } from './reducers/settings';
import { footerReducer } from './reducers/footer';
import { headerReducer } from './reducers/header';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

Sentry.init({
  dsn: 'https://440c5144138940f98041ff9e835c2a2f@sentry.blueshoe.de/31',
});

export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error: any) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

// Only set to sentry on production
const providers = [Title];
if (environment.production) {
  // @ts-ignore
  providers.push({ provide: ErrorHandler, useClass: SentryErrorHandler });
}

@NgModule({
  declarations: [
    AppComponent,
    BenefitsTileComponent,
    ContentTeaserHalfComponent,
    ContentTeaserHalfGenericComponent,
    ContentTeaserHalfStoryComponent,
    DownloadBoxComponent,
    FooterComponent,
    FooterMainNavigationComponent,
    FooterPreNavigationComponent,
    FooterSloganComponent,
    FooterSocialMediaComponent,
    GlobalNetworkLinklistComponent,
    GlobalNetworkTeaserComponent,
    HeaderComponent,
    HeaderContactUsComponent,
    HeaderNavigationComponent,
    HeaderNavigationLinkComponent,
    HeaderNavigationMobileComponent,
    HeaderNavigationMobileItemComponent,
    HeaderSubnavigationComponent,
    HorizontalDividerComponent,
    ImageComponent,
    ImageFullSizeComponent,
    InfoBoxComponent,
    LayoutDefaultComponent,
    LinkBackComponent,
    LinkComponent,
    MainContentElementComponent,
    NotfoundRouteComponent,
    ProductTeaserFullComponent,
    QuoteComponent,
    RouteGlobalComponent,
    RouteNotfoundComponent,
    SolutionTeaserBoxesComponent,
    SpacerComponent,
    StageComponent,
    StageContactComponent,
    TeaserBoxComponent,
    TextComponent,
    TextFullSizeComponent,
    TextHeaderImageLinkComponent,
    TextHtmlComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'agb-frontend' }),
    BrowserTransferStateModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NxButtonModule,
    NxCopytextModule,
    NxErrorModule,
    NxFooterModule,
    NxGridModule,
    NxHeaderModule,
    NxHeadlineModule,
    NxIconModule,
    NxInputModule,
    NxLinkModule,
    NxModalModule.forRoot(),
    NxPaginationModule,
    NxPopoverModule,
    NxSpinnerModule,
    NxTabsModule,
    NxVideoModule,
    ReactiveFormsModule,
    TransferHttpCacheModule,
    StoreModule.forRoot({
      settings: settingsReducer,
      footer: footerReducer,
      header: headerReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
