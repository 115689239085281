// Modules
import { Component, OnInit, Input } from '@angular/core';

// Definitions
import { IImage } from 'src/app/components/image/image.component';
import { ILink } from 'src/app/components/link/link.component';

interface ISolutionTeaserBox {
  description: string;
  image: IImage;
  link: ILink;
  title: string;
  preTitle: string;
  backgroundColor: string;
  full_width: boolean;
}

interface ISolutionTeaserBoxes {
  type: 'solutionTeaserBoxes';
  full_width: boolean;
  boxes: ISolutionTeaserBox[];
}

// Component
@Component({
  selector: 'app-solution-teaser-boxes',
  templateUrl: './solution-teaser-boxes.component.html',
  styleUrls: ['./solution-teaser-boxes.component.scss'],
})
export class SolutionTeaserBoxesComponent implements OnInit {
  @Input() component: ISolutionTeaserBoxes;

  constructor() {}

  ngOnInit() {
    // TODO: Remove before deployment
    // this.component.boxes = this.component.boxes.map(
    //   (box: ISolutionTeaserBox) => {
    //     box.backgroundColor = '#E6F4F6';
    //     return box;
    //   }
    // );
  }
}
