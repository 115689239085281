import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-divider',
  templateUrl: './horizontal-divider.component.html',
  styleUrls: ['./horizontal-divider.component.scss']
})
export class HorizontalDividerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
