// Modules
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { IFooter } from '../../reducers/footer';

@Component({
  selector: 'app-route-notfound',
  templateUrl: './route-notfound.component.html',
  styleUrls: ['./route-notfound.component.scss'],
})
export class RouteNotfoundComponent {
  supportEmail$: Observable<string>;

  constructor(private store: Store<IFooter>) {
    this.supportEmail$ = this.store.pipe(select('settings', 'supportEmail'));
  }
}
