// Modules
import { Component, Input } from '@angular/core';

// Definitions
import { IImage } from '../image/image.component';
interface IImageFullSizeComponent {
  image: IImage;
}
@Component({
  selector: 'app-image-full-size',
  templateUrl: './image-full-size.component.html',
  styleUrls: ['./image-full-size.component.scss'],
})
export class ImageFullSizeComponent {
  @Input() component: IImageFullSizeComponent;
}
