// Modules
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { IFooter } from '../../reducers/footer';

@Component({
  selector: 'app-footer-slogan',
  templateUrl: './footer-slogan.component.html',
  styleUrls: ['./footer-slogan.component.scss'],
})
export class FooterSloganComponent implements OnInit {
  slogan$: Observable<string>;

  constructor(private store: Store<IFooter>) {
    this.slogan$ = this.store.pipe(select('footer', 'slogan'));
  }

  ngOnInit() {}
}
