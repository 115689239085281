// Modules
import { Component, Output, EventEmitter, Input } from '@angular/core';

// Defimitions
export interface INavigationLink {
  url: string;
  label: string;
  external?: boolean;
  children?: INavigationLink[];
  type: string;
}

@Component({
  selector: 'app-header-navigation-link',
  templateUrl: './header-navigation-link.component.html',
  styleUrls: ['./header-navigation-link.component.scss'],
})
export class HeaderNavigationLinkComponent {
  @Input() navigationItem: INavigationLink;
  @Input() isSubNav: boolean;
  @Output() handleHover: EventEmitter<INavigationLink> = new EventEmitter<
    INavigationLink
  >();

  onHover = (navitem: INavigationLink) => this.handleHover.emit(navitem);
}
