// Modules
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { INavigationLink } from '../header-navigation-link/header-navigation-link.component';
import { IHeader } from '../../reducers/header';
import { IHeaderContactUs } from '../header-contact-us/header-contact-us.component';

// Component
@Component({
  selector: 'app-header-navigation-mobile',
  templateUrl: './header-navigation-mobile.component.html',
  styleUrls: ['./header-navigation-mobile.component.scss'],
})
export class HeaderNavigationMobileComponent {
  navigation$: Observable<INavigationLink[]>;
  contact$: Observable<IHeaderContactUs>;

  isActive: boolean;

  constructor(private store: Store<IHeader>) {
    this.navigation$ = this.store.pipe(select('header', 'navigation'));
    this.contact$ = this.store.pipe(select('header', 'contact'));
  }

  toggleMenu = () => (this.isActive = !this.isActive);

  hideNav = (toHide: boolean) => (this.isActive = !toHide);
}
