// Modules
import { createAction, props } from '@ngrx/store';

// Definitions
import { ISettingsAPI } from '../reducers/settings';

// Actions
export const initSettingsAction = createAction(
  '[Global] Init basic settings',
  props<ISettingsAPI>()
);
