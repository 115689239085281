// modules
import { Component, Input } from '@angular/core';

// Definitions
interface ISpacer {
  type: 'spacer';
  height: 's' | 'l' | 'm' | 'xl';
}
@Component({
  selector: 'app-spacer',
  templateUrl: './spacer.component.html',
  styleUrls: ['./spacer.component.scss'],
})
export class SpacerComponent {
  @Input() component: ISpacer;
}
