// Modules
import { Component, Input } from '@angular/core';

// Definitions
import { IContentColumn } from '../content-teaser-half/content-teaser-half.component';

// Component
@Component({
  selector: 'app-content-teaser-half-generic',
  templateUrl: './content-teaser-half-generic.component.html',
  styleUrls: ['./content-teaser-half-generic.component.scss'],
})
export class ContentTeaserHalfGenericComponent {
  @Input() component: IContentColumn;
}
