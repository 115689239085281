// Modules
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationStart } from '@angular/router';

// Components
import { ApiService } from '../../api.service';

// Definitions
interface PageComponent {
  type: string;
}

@Component({
  selector: 'app-route-global',
  templateUrl: './route-global.component.html',
  styleUrls: ['./route-global.component.scss'],
})
export class RouteGlobalComponent implements OnInit {
  pageComponents: PageComponent[];
  error: boolean;

  constructor(
    private router: Router,
    private titleService: Title,
    private apiService: ApiService
  ) {
    this.pageComponents = [];
  }

  async initData(url: string) {
    // Get data from API
    const result: any = await this.apiService.getData(url);

    const { title, components = [], error } = result;

    // Check if this is a valid site
    if (error && error.code === 'not-verified') {
      this.error = error;
      return;
    }

    this.titleService.setTitle(`Allianz Global Benefits - ${title}`);
    this.pageComponents = components;
  }

  ngOnInit() {
    // Init data on start
    this.initData(this.router.url);

    // Listen to all routing changes to show other content
    this.router.events.subscribe(value => {
      if (value instanceof NavigationStart) {
        this.initData(value.url);
      }
    });
  }
}
