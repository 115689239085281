// Modules
import { Component, OnInit, Input } from '@angular/core';

// Definitions
import { IImage } from 'src/app/components/image/image.component';
import { ILink } from 'src/app/components/link/link.component';

interface IGlobalNetworkLinkListItem {
  title: string;
  description: string;
  image: IImage;
  link: ILink;
  logo: IImage;
  checklist: string[];
}

interface IGlobalNetworkCountry {
  code: string;
  icon: string;
  label: string;
  checklist: IGlobalNetworkLinkListItem[];
}

interface IGlobalNetworkRegion {
  label: string;
  countries: IGlobalNetworkCountry[];
}

interface IGlobalNetworkLinkList {
  title: string;
  type: 'globalNetworkLinkList';
  regions: IGlobalNetworkRegion[];
}

// Component
@Component({
  selector: 'app-global-network-linklist',
  templateUrl: './global-network-linklist.component.html',
  styleUrls: ['./global-network-linklist.component.scss'],
})
export class GlobalNetworkLinklistComponent implements OnInit {
  @Input() component: IGlobalNetworkLinkList;
  detailLink: IGlobalNetworkLinkListItem | null;
  modalOpen: boolean;
  checkListHTML: string;
  page = 0;
  count = 0;
  perPage = 10;
  activeLinklist: IGlobalNetworkLinkListItem[];

  prevPage() {
    this.page--;
    this.showModalContent();
  }

  nextPage() {
    this.page++;
    this.showModalContent();
  }

  goToPage(n: number) {
    this.page = n;
    this.showModalContent();
  }

  showModalContent() {
    this.detailLink = this.activeLinklist[this.page - 1];
  }

  ngOnInit() {
    // Remove regions without any countrys
    this.component.regions = this.component.regions.filter(
      (region: IGlobalNetworkRegion) => region.countries.length
    );
  }

  showLinkDetail(
    link: IGlobalNetworkLinkListItem,
    linklist: IGlobalNetworkLinkListItem[],
    index: number
  ) {
    this.modalOpen = true;
    this.detailLink = link;
    this.activeLinklist = linklist;

    // Create HTML to use the app-text-html
    this.checkListHTML = `<ul>${this.detailLink.checklist
      .map(item => `<li>${item}</li>`)
      .join('')}</ul>`;

    // Handle modal pagination
    if (linklist && linklist.length) {
      this.page = index + 1;
      this.count = this.perPage * linklist.length;
    }
  }
}
