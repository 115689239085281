// Modules
import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { INavigationLink } from '../header-navigation-link/header-navigation-link.component';
import { IFooter } from '../../reducers/footer';
import { IImage } from '../image/image.component';

export interface IFooterSection {
  label: FunctionStringCallback;
  children: INavigationLink[];
}

@Component({
  selector: 'app-footer-pre-navigation',
  templateUrl: './footer-pre-navigation.component.html',
  styleUrls: ['./footer-pre-navigation.component.scss'],
})
export class FooterPreNavigationComponent implements OnInit {
  sections$: Observable<string>;
  illustration$: Observable<IImage>;
  image: string | null;

  constructor(private store: Store<IFooter>) {
    this.sections$ = this.store.pipe(select('footer', 'pre', 'sections'));
    this.illustration$ = this.store.pipe(
      select('footer', 'pre', 'illustration')
    );
  }

  ngOnInit() {}
}
