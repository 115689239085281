// Modules
import { createAction, props } from '@ngrx/store';

// Definitions
import { IFooterAPI } from '../reducers/footer';

// Actions
export const initFooterAction = createAction(
  '[Global] Init footer',
  props<IFooterAPI>()
);
