// Modules
import { createAction, props } from '@ngrx/store';

// Definitions
import { IHeaderAPI } from '../reducers/header';

// Actions
export const initHeaderAction = createAction(
  '[Global] Init header',
  props<IHeaderAPI>()
);
