// Modules
import { createReducer, on } from '@ngrx/store';

// Actions
import { initSettingsAction } from '../actions/settings';

// Definitions
export interface IPathMapping {
  [name: string]: {
    id: number;
  };
}
export interface ISettings {
  supportEmail: string;
  pathMapping: IPathMapping;
}

export interface ISettingsAPI {
  support_email: string;
  path_mapping: IPathMapping;
}

export interface ISettingsAction extends ISettingsAPI {
  type: string;
}

// Defaults
export const initialState = {
  supportEmail: '',
  pathMapping: {},
};

// Reducer
const initSettings = (state: ISettings, settings: ISettingsAPI) => {
  // Get only neccessary keys
  const { support_email: supportEmail, path_mapping: pathMapping } = settings;

  return {
    ...state,
    supportEmail,
    pathMapping,
  };
};

export function settingsReducer(state: ISettings, action: ISettingsAction) {
  return createReducer(initialState, on(initSettingsAction, initSettings))(
    state,
    action
  );
}
