// Modules
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { environment } from '../../../environments/environment';

// Definitions
import { INavigationLink } from '../header-navigation-link/header-navigation-link.component';

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
})
export class HeaderNavigationComponent {
  @Input() navigationsItems: INavigationLink[];
  @Output() handleSubNav: EventEmitter<INavigationLink> = new EventEmitter<
    INavigationLink
  >();

  isProduction = environment.production;

  devMenu = {
    label: 'Components (DEV)',
    url: '/dev',
    type: 'internalLink',
  };

  showSubMenu = (navitem: INavigationLink) => this.handleSubNav.emit(navitem);
}
