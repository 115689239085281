// Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { ISettings, IPathMapping } from './reducers/settings';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  mockedRoot = '/assets/data';
  apiRoot = environment.API_URL;

  pathMapping$: Observable<{ IPathMapping }>;

  constructor(
    private http: HttpClient,
    private state: TransferState,
    private store: Store<ISettings>
  ) {
    this.pathMapping$ = this.store.pipe(select('settings', 'pathMapping'));
  }

  async get(url: string, stateKey: any) {
    // Get the data from state
    const stateData = this.state.get(stateKey, {
      title: '',
      components: [],
      error: null,
    });

    // Check if data is already loaded
    // if (stateData && Object.keys(stateData).length) {
    //   return Promise.resolve(stateData);
    // }

    return this.http
      .get(`${url}/?format=json`)
      .toPromise()
      .then((data: any) => {
        this.state.set(stateKey, data);
        return data;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  getValidPath(path: string, mapping: IPathMapping) {
    // TODO: Remove this temprorary fix
    path = path.replace('/home', '');

    // Last character must always be /
    if (path.slice(-1) !== '/') {
      path = `${path}/`;
    }

    return mapping[path];
  }

  checkMockedRoutes(path: string, callback) {
    // Routes where to mock the data
    const mockedRoutes = [
      '/dev',
      '/dev/stage',
      '/dev/quote',
      '/dev/download_box',
      '/dev/info_box',
      '/dev/product_teaser_full',
      '/dev/image_full_size',
      '/dev/text_full_size',
      '/dev/global_network_teaser',
      '/dev/teaser_box',
    ];

    if (mockedRoutes.indexOf(path) > -1) {
      const filename = `${path
        .slice(1)
        .split('?')[0]
        .replace(new RegExp('/', 'g'), '_')}.json`;

      return callback(
        this.get(`${this.mockedRoot}/${filename}`, makeStateKey(path))
      );
    }

    callback({ error: { code: 'not-verified' } });
  }

  getData(path: string) {
    return new Promise(async resolve => {
      // Set defaults
      let isFetching = false;

      // Wait for all mappings
      this.pathMapping$.subscribe(async mapping => {
        // Check if there is a request running
        if (isFetching) {
          return;
        }

        // Wait until there are mapping items
        if (!Object.keys(mapping).length) {
          return;
        }

        isFetching = true;

        // Check if this url is allowed
        const pathInfo = this.getValidPath(path, mapping);
        if (!pathInfo) {
          // return resolve({ error: { code: 'not-verified' } });
          // TODO: Remove mocking before deployment
          this.checkMockedRoutes(path, resolve);
          isFetching = false;
          return;
        }

        // Default the url is the API
        const apiResult = await this.get(
          `${this.apiRoot}/pages/${pathInfo.id}`,
          makeStateKey(path)
        );

        isFetching = false;
        resolve(apiResult);
      });
    });
  }

  getMeta() {
    // Only for mocking
    // return this.get(
    //   `${this.mockedRoot}/general_data.json`,
    //   makeStateKey('general_data')
    // );

    const path = 'general_data';
    return this.get(`${this.apiRoot}/${path}`, makeStateKey(path));
  }
}
