// Modules
import { Component, Input } from '@angular/core';

// Definitions
import { IImage } from '../image/image.component';
import { ILink } from '../link/link.component';

interface ITeaserBox {
  type: 'teaserBox';
  title: string;
  text: string;
  preTitle?: string;
  image?: IImage;
  link?: ILink;
  backgroundColor: string;
}

// Component
@Component({
  selector: 'app-teaser-box',
  templateUrl: './teaser-box.component.html',
  styleUrls: ['./teaser-box.component.scss'],
})
export class TeaserBoxComponent {
  @Input() component: ITeaserBox;
}
