// Modules
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { INavigationLink } from '../header-navigation-link/header-navigation-link.component';
import { IFooter } from '../../reducers/footer';

@Component({
  selector: 'app-footer-main-navigation',
  templateUrl: './footer-main-navigation.component.html',
  styleUrls: ['./footer-main-navigation.component.scss'],
})
export class FooterMainNavigationComponent {
  mainNavigation$: Observable<INavigationLink[]>;
  currentYear = new Date().getFullYear();

  constructor(private store: Store<IFooter>) {
    this.mainNavigation$ = this.store.pipe(select('footer', 'mainNavigation'));
  }
}
