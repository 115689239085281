// Modules
import { Component, OnInit, Input } from '@angular/core';

// Definitions
interface ITextHeaderImageLinkComponent {
  title: string;
  description: string;
  link: {
    url: string;
    label: string;
  };
}

@Component({
  selector: 'app-text-header-image-link',
  templateUrl: './text-header-image-link.component.html',
  styleUrls: ['./text-header-image-link.component.scss'],
})
export class TextHeaderImageLinkComponent implements OnInit {
  @Input() component: ITextHeaderImageLinkComponent;

  constructor() {}

  ngOnInit() {}
}
