// Modules
import { Component, Input } from '@angular/core';

// Definitions
import { IImage } from '../image/image.component';

interface IHTMLText {
  type: 'text';
  text: string;
}
interface IMainContentElement {
  type: string;
  title: string;
  left: Array<IImage | IHTMLText>;
  right: Array<IImage | IHTMLText>;
}

// Component
@Component({
  selector: 'app-main-content-element',
  templateUrl: './main-content-element.component.html',
  styleUrls: ['./main-content-element.component.scss'],
})
export class MainContentElementComponent {
  @Input() component: IMainContentElement;
}
