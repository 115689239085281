import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-full-size',
  templateUrl: './text-full-size.component.html',
  styleUrls: ['./text-full-size.component.scss'],
})

export class TextFullSizeComponent {
  @Input() component;
}
