// Modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Routes
import { RouteGlobalComponent } from './components/route-global/route-global.component';

const routes: Routes = [
  {
    path: '**',
    component: RouteGlobalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
