// Modules
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Definitions
import { ILink } from 'src/app/components/link/link.component';
import { IImage } from 'src/app/components/image/image.component';
import { IHeader } from '../../reducers/header';

export interface IHeaderContactUs {
  button: ILink;
  email: ILink;
  image: IImage;
  label: string;
  phone: ILink;
}

// Component
@Component({
  selector: 'app-header-contact-us',
  templateUrl: './header-contact-us.component.html',
  styleUrls: ['./header-contact-us.component.scss'],
})
export class HeaderContactUsComponent {
  contact$: Observable<IHeaderContactUs>;
  popoverManualOpenFlag: boolean;

  constructor(private store: Store<IHeader>) {
    this.contact$ = this.store.pipe(select('header', 'contact'));
  }
}
